import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h4 className="heading-h2"><span className="bluecolor">Top Reasons</span> to Choose Our White Label Crypto Wallet Development</h4>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel-wallet/top-reason.webp"
                alt="Top Reason to choose white lable crypto wallet image1"
                placeholder='none'
                width={500}
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Our White label Crypto Wallet is for unimaginable success in your crypto business realm. We excel in providing exceptional crypto wallet app development tailored for emerging startups. Utilizing cutting-edge technology tools, we craft crypto wallets that enable stress-free transfer of digital assets for your users. Our dedicated developer team is committed to delivering high-quality crypto wallet development, ensuring your satisfaction and success in the dynamic market. For the best customization and expert services, Coinsclone earned a solid reputation as an ideal cryptocurrency wallet development solution.
              </p>
              <ul>
                <li>50+ well-trained blockchain professionals.</li>
                <li>On-time delivery.</li>
                <li>Only upfront costs ( No hidden cost) with best quality.</li>
                <li>24*7 technical and customer support.</li>
                <li>Successfully deployed 100+ crypto wallets on various chains.</li>
                <li className='mb-0'>Masters in curating highly responsive and scalable crypto wallets.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose