import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h6 className="heading-h2 text-center">Frequently Asked Questions?</h6>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is a White label Crypto Wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">White label Crypto Wallet is a ready-made software solution that enables you to promptly launch your own branded crypto wallet which makes it easier for the user to buy, sell, and swap the cryptos. You can customize according to your ideas with features such as multi-asset support, robust private key management, and a user-friendly interface for a hassle-free experience.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What are the Benefits of Developing a White Label Crypto Wallet?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The benefits of developing a White label Crypto Wallet are it helps with user engagement and budget-friendly options. With no delay, you can easily launch your business. It is an easy way to create branding for your crypto wallet business.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How Much Will It Cost to Develop a White-label Crypto Wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The approximate cost to deploy a White-label Crypto Wallet ranges between $10,000 to $25,000. Depending upon the customization, and add-on features, the cost may slightly vary.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How Long Does it Take to Deploy a White Label Crypto Wallet?  
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Approximately it will take 15- 30 days to deploy a White-label Crypto Wallet. For the complex customization and add-ons, the time may increase further.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How can I create a white label crypto wallet with your service?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">You can create a white-label crypto wallet by partnering with us, where we provide a customizable solution to your branding and features. Reach out to our team to discuss your requirements.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                How can a white label crypto wallet generate revenue for my business?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">A white-label cryptocurrency wallet can generate revenue via transaction fees, subscription models, and premium features such as staking, trading, and asset management services.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle"  eventKey="6">
                Is a multi-chain crypto wallet secure?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">Yes, a multi-chain crypto wallet can be secure when developed with robust security protocols, such as encryption, two-factor authentication, and regular security audits. Its design involves integrating various blockchain protocols, & technologies and enables support for multiple cryptocurrencies while ensuring the safety of user assets.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '7' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('7')} className="panel-toggle"  eventKey="7">
                How do I get started with developing a white label crypto wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="7">
                <div className="panel-body card-body">To get started, contact our team to discuss your ideas and requirements. We’ll guide you through the customization process, provide necessary technical support, and help you deploy your wallet affordably and rapidly.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection