import React, { Component } from 'react'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="usr-admin-tab pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">White Label Crypto Wallet: Explore Our <span className="bluecolor">Feature Set!</span></h2>
            <p className='text-center'>Explore our versatile features, thoughtfully categorized into a range of simple to advanced options. We fuel your crypto wallet business for profitability.</p>
          <div className="row">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  <h3>Basic Features</h3>
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  <h3>Advanced Features</h3>
                </li>
              </ul>

              <div className="tab-content px-0">
                <div id="tab1" className="tab-pane tabs_items active">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <p className='head3'>Add Custom Tokens</p>
                            <p className='pharagraph'>Manually add and manage the cryptos that are not supported automatically by the wallet.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Ledger Support</p>
                            <p className='pharagraph'>Facilitates users to save and manage often-used crypto addresses using this feature.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Wallet connection</p>
                            <p className='pharagraph'>Enabling secure communication and interaction between a web dApp and a mobile crypto wallet.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Two-Factor Biometric Authentication</p>
                            <p className='pharagraph'>Biometric authentication, like fingerprint or facial recognition, or an OTP can provide security safeguards.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>NFT Collectibles</p>
                            <p className='pharagraph'>Offering Non-Fungible Tokens (NFT) and presenting them as owners for the digital asset items.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Multiple Wallet Support </p>
                            <p className='pharagraph'>Users can create, and manage multiple wallets and also have a wallet dashboard to carry the essential information.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Native Fiat Currency Selection</p>
                            <p className='pharagraph'>This allows your users to choose their preferred fiat currency and see the conversion value of their crypto assets accordingly.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Asset Management</p>
                            <p className='pharagraph'>Users can quickly manage and keep an eye on their crypto assets. Tracks user portfolios, and transaction histories & generates detailed reports.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Export and Import Private Keys</p>
                            <p className='pharagraph'>Users can protect their cryptos securely by exporting and importing the private keys. Promising your user, complete wallet control.</p>
                        </div>
                    </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <p className='head3'>Multichain Wallet Networks</p>
                            <p className='pharagraph'>Our White label Crypto Wallet serves a multiverse of blockchain networks that allow users to access their cryptos in one place.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Cross and On-chain crypto swap</p>
                            <p className='pharagraph'>Users can exchange cryptos directly on the blockchain as well as across several blockchain networks without relying on external exchange.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Chats</p>
                            <p className='pharagraph'>Non-stop real-time communications and instant partnership for upgraded crypto asset management and support.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Multichain dApps Browser</p>
                            <p className='pharagraph'>Our White-label Wallet offers decentralized application (DApps) browsers and access to DeFi services.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Real-time Tracking and Trading Insights</p>
                            <p className='pharagraph'>Our White-label Wallet enables tracking and sending notifications. It allows users to set price alerts to never miss an opportunity.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Prepaid Cards</p>
                            <p className='pharagraph'>Get access to prepaid debit cards for making payments. Users can also top up the card with crypto and obtain a fiat equivalent balance. </p>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures