import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h3 className="heading-h2 text-center">
            <span className="heading-h3">Why is a White label Crypto Wallet</span> <span className="bluecolor">beneficial for</span> startups?
            </h3>
            <p className="text-center">
            Our White label Crypto Wallet offers startups a quick ready-made infrastructure and customization options. Hence, you can enter the market swiftly with a branded cryptocurrency solution.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <p className="head3">Less Developmental Risk</p>
                <p className="pharagraph">
                To mitigate the chances of technical issues and project failures, a safer alternative would be picking a ready-made wallet solution. This approach reduces developmental risk and accelerates time-to-market.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Multi Coin Options</p>
                <p className="pharagraph">
                To reach a broader market, your crypto wallet must support a diverse range of cryptocurrencies. Our Crypto Wallet offers instant custodial support for various coins and tokens, eliminating manual configuration.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Faster Time-To-Market</p>
                <p className="pharagraph">
                It's an incredible benefit of our White-label Wallet. Without any time constraints, you can readily deploy it, significantly shortening the time needed to launch your crypto business and gain an advantage.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Cost - Effectiveness</p>
                <p className="pharagraph">
                Creating something from scratch requires a deep pocket. With our White-label Wallet, your wallet can do a happy dance. It offers a one-stop solution for high-quality and reasonably priced ones.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Ongoing Updates</p>
                <p className="pharagraph">
                Minimizing the huge procedure involved in releasing security fixes for Android and iOS. White label digital wallet saves the valuable time and effort required to ensure timely software updates.
                </p>
              </div>  
              <div className="square" >
                <p className="head3">Option to host own servers</p>
                <p className="pharagraph">
                Crypto wallets rely on servers to hold the cryptos in place for every user. With our White label Wallet, we give you the choice to host your servers. This empowers operators with greater control over their service options.
                </p>
              </div>
            </div>
            <div className="mt-3 text-center">
                <ButtonComponent />
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default Benificial
